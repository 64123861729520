// Static data
const staticData = {
    "tickets": [
        {
            "id": "CAM-1",
            "title": "Update User Profile Page UI",
            "tag": ["Feature request"],
            "userId": "usr-1",
            "status": "Todo",
            "priority": 4
        },
        {
            "id": "CAM-2",
            "title": "Add Multi-Language Support - Enable multi-language support within the application.",
            "tag": ["Feature Request"],
            "userId": "usr-2",
            "status": "In progress",
            "priority": 3
        },
        {
            "id": "CAM-3",
            "title": "Optimize Database Queries for Performance",
            "tag": ["Feature Request"],
            "userId": "usr-2",
            "status": "In progress",
            "priority": 1
        },
        {
            "id": "CAM-4",
            "title": "Implement Email Notification System",
            "tag": ["Feature Request"],
            "userId": "usr-1",
            "status": "In progress",
            "priority": 3
        },
        {
            "id": "CAM-5",
            "title": "Enhance Search Functionality",
            "tag": ["Feature Request"],
            "userId": "usr-5",
            "status": "In progress",
            "priority": 0
        },
        {
            "id": "CAM-6",
            "title": "Third-Party Payment Gateway",
            "tag": ["Feature Request"],
            "userId": "usr-2",
            "status": "Todo",
            "priority": 1
        },
        {
            "id": "CAM-7",
            "title": "Create Onboarding Tutorial for New Users",
            "tag": ["Feature Request"],
            "userId": "usr-1",
            "status": "Done",
            "priority": 2
        },
        {
            "id": "CAM-8",
            "title": "Implement Role-Based Access Control (RBAC)",
            "tag": ["Feature Request"],
            "userId": "usr-3",
            "status": "In progress",
            "priority": 3
        },
        {
            "id": "CAM-9",
            "title": "Upgrade Server Infrastructure",
            "tag": ["Feature Request"],
            "userId": "usr-5",
            "status": "Todo",
            "priority": 2
        },
        {
            "id": "CAM-10",
            "title": "Conduct Security Vulnerability Assessment",
            "tag": ["Feature Request"],
            "userId": "usr-4",
            "status": "Canceled",
            "priority": 1
        }
    ],
    "users": [
        {
            "id": "usr-1",
            "name": "Anoop Sharma",
            "available": false
        },
        {
            "id": "usr-2",
            "name": "Yogesh",
            "available": true
        },
        {
            "id": "usr-3",
            "name": "Shankar Kumar",
            "available": true
        },
        {
            "id": "usr-4",
            "name": "Ramesh",
            "available": true
        },
        {
            "id": "usr-5",
            "name": "Suresh",
            "available": true
        }
    ]
};

// Action to fetch all data
export const fetchAllData = () => async (dispatch) => {
    try {
        dispatch({ type: 'DATA_REQUEST' });

        // Use static data instead of API call
        const data = staticData;

        // Modify the data here
        const modifiedTickets = data.tickets.map(ticket => ({
            ...ticket,
            isHighPriority: ticket.priority === 4, // Example modification
            title: ticket.title.toUpperCase(),
        }));

        const modifiedUsers = data.users.map(user => ({
            ...user,
            fullName: user.name,
        }));

        dispatch({ type: 'DATA_SUCCESS', payload: { tickets: modifiedTickets, users: modifiedUsers } });
    } catch (error) {
        dispatch({ type: 'DATA_FAILURE', payload: error.message });
    }
};

// Action to select data based on grouping and sorting options (remains unchanged)
export const selectData = (group, allTickets, orderValue) => async (dispatch) => {
    try {
        dispatch({ type: 'SELECT_DATA_REQUEST' });

        let user = false;
        let mySet = new Set();
        let arr = [], selectedData = [];

        // Handle grouping by status
        if (group === 'status') {
            allTickets.forEach((elem) => {
                mySet.add(elem.status);
            });

            arr = [...mySet]; // Spread the set into an array

            arr.forEach((elem, index) => {
                let filteredArr = allTickets.filter((fElem) => {
                    return elem === fElem.status;
                });
                selectedData.push({
                    [index]: {
                        title: elem,
                        value: filteredArr
                    }
                });
            });
        }

        // Handle grouping by user
        else if (group === 'user') {
            user = true;
            if (allTickets && allTickets.allUser) {
                allTickets.allUser.forEach((elem, index) => {
                    let filteredArr = allTickets.allTickets.filter((fElem) => {
                        return elem.id === fElem.userId;
                    });

                    selectedData.push({
                        [index]: {
                            title: elem.name,
                            value: filteredArr
                        }
                    });
                });
            }
        }

        // Handle grouping by priority
        else {
            let prior_list = ["No priority", "Low", "Medium", "High", "Urgent"];

            prior_list.forEach((elem, index) => {
                let filteredArr = allTickets.filter((fElem) => {
                    return index === fElem.priority;
                });

                selectedData.push({
                    [index]: {
                        title: elem,
                        value: filteredArr
                    }
                });
            });
        }

        // Sorting by title
        if (orderValue === "title") {
            selectedData.forEach((elem, index) => {
                if (elem[index] && elem[index].value) {
                    elem[index].value.sort((a, b) => a.title.localeCompare(b.title));
                }
            });
        }

        // Sorting by priority
        if (orderValue === "priority") {
            selectedData.forEach((elem, index) => {
                if (elem[index] && elem[index].value) {
                    elem[index].value.sort((a, b) => b.priority - a.priority);
                }
            });
        }

        dispatch({ type: 'SELECT_DATA_SUCCESS', payload: { selectedData, user } });

    } catch (error) {
        dispatch({ type: 'SELECT_DATA_FAILURE', payload: error.message });
    }
};

